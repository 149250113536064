import PropTypes from 'prop-types';
import {Col, Container, Row} from 'react-bootstrap';

const SubTopicsStepsTemplate = ({stepsArray, SummingUp}) =>
    <>
        <ol className={'text-justify'}>
            {stepsArray}
        </ol>
        <hr/>
        <Container className={'p-3 text-justify'}>
            <Row>
                <StepHeading>To sum up</StepHeading>
            </Row>
            <Row>
                <Col>
                    {SummingUp}
                </Col>
            </Row>
        </Container>
    </>;

const CodeBlockContainer = ({children}) =>
    <Container className={'bg-light py-3 m-3 text-truncate overflow-auto'}>
        <Row>
            <Col>
                <code>
                    {children}
                </code>
            </Col>
        </Row>
    </Container>;

const RowInACodeBlock = ({children}) =>
    <Row className={'px-3'}>
        {children}
    </Row>;

const StepHeading = ({children}) =>
    <h3 className={'text-primary h5'}>
        {children}
    </h3>;

export {
    SubTopicsStepsTemplate,
    CodeBlockContainer, RowInACodeBlock, StepHeading
};

SubTopicsStepsTemplate.propTypes = {
    stepsArray: PropTypes.array.isRequired,
};
