import RenderTopics from './NotesComponents/RenderTopics';
import {notes} from './resources/topicsJsonData';
import RenderTitle from '../../customHooks/RenderTitle';

const Notes = () => {

    const notesPageTitle = notes.notesText;

    const NotesPage = () =>
        <>
            <RenderTitle titleOfThePage={notesPageTitle}/>
            <RenderTopics/>
        </>

    return (
        <>
            <NotesPage/>
        </>
    );
};

export default Notes;
