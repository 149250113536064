import {Nav, Navbar} from 'react-bootstrap';

const NavigationBar = () => {

    const Header = () => {

        const logoIconStyle = 'text-primary font-weight-bold';
        const openBraces = '<';
        const closeBraces = '>';

        return (
            <div>
                <span className={logoIconStyle}>{openBraces}</span>
                <span>&nbsp;Keerthi&nbsp;</span>
                <span className={logoIconStyle}>{closeBraces}</span>
            </div>
        );
    };

    const HeaderLogoBrand = () =>
        <Navbar.Brand href="/">
            <Header/>
        </Navbar.Brand>
    ;

    const NotesLinkInNavigationBar = () =>
        <Nav.Link href={'/notes'}
                  className={'text-dark fst-italic p-0 mx-4'}
        >
        <span className={'border-bottom border-primary'}>
            Notes
        </span>
        </Nav.Link>
    ;

    const RenderNavigationBar = () =>
        <Navbar expand={'sm'}>
            <HeaderLogoBrand/>
            <Navbar.Toggle aria-controls={'basic-navbar-nav'}/>
            <Navbar.Collapse id={'basic-navbar-nav'}>
                <Nav>
                    <NotesLinkInNavigationBar/>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    ;

    return <RenderNavigationBar/>;
};

export default NavigationBar;
