import {Accordion, Card} from "react-bootstrap"

const Mitigram = () => {

    const Heading = () => <h4 className={'fst-italic'}>Mitigram</h4>

    const AboutTheCompany = () =>
        <p>
            A financial trade software company that helps buyers and sellers across businesses located anywhere in the
            world to seamlessly perform business transactions and not worry about
            timely payments
        </p>

    const Role = () =>
        <div>
            <h5>Role</h5>
            <p>Test Automation Engineer</p>
        </div>

    const Duration = () =>
        <div>
            <h5>Duration</h5>
            <p>2021 to 2023</p>
        </div>

    const ContributionHeading = () => <h5>Key responsibilities</h5>
    const ContributionDescription = () =>
        <ul>
            <li>
                Write test automation scripts for system testing to minimize testing time
            </li>
            <li>
                Keep track of builds in build system to track for failures and fix them
            </li>
            <li>
                Report bugs to respective teams on a daily basis from overnight scheduled runs
            </li>
            <li>
                Maintain / improve the test automation framework with Puppeteer and TypeScript
            </li>
        </ul>

    const Contribution = () =>
        <div>
            <ContributionHeading/>
            <ContributionDescription/>
        </div>

    const uniqueKey = "5"

    return (
        <Card className={'border-top border-left border-right border-info'}>
            <Accordion.Item eventKey={uniqueKey}>
                <Accordion.Header as={Card.Header} eventKey={uniqueKey}>
                    <Heading/>
                </Accordion.Header>
                <Accordion.Collapse eventKey={uniqueKey}>
                    <Card.Body>
                        <AboutTheCompany/>
                        <Role/>
                        <Duration/>
                        <Contribution/>
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion.Item>
        </Card>
    )
}

export default Mitigram
