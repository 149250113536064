import {Container} from 'react-bootstrap';
import {topicNames} from '../../../resources/topicsJsonData';
import {calculateCurrentRouteWithMainAndSubTopics} from '../../../resources/calculateCurrentRoute';
import {LiWithVerticalPaddingTwo, SubTopic, UlWithLeftPaddingZero} from '../../TopicTemplates/SubTopicTemplate';
import StepsGradle from './StepsGradle';
import RenderTitle from '../../../../../customHooks/RenderTitle';
import BreadCrumbsNavigation from "../../../BreadCrumbsNavigation";

const Gradle = () => {

    const mainTopic = topicNames.java.mainTopic;

    const subTopic = topicNames.java.subTopics.gradle;

    const currentRoute = calculateCurrentRouteWithMainAndSubTopics(mainTopic, subTopic);

    const ThisPageDiv = () =>
        <Container className={'text-justify'}>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    <a href={'https://gradle.org/'}>Gradle</a> is a build tool for programming languages like Java.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    Using a build tool decreases effort in dependency management, automation and helps to integrate
                    with CI/CD tools like Jenkins.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    Gradle is an alternative for Maven. An advantage of using Gradle over Maven is that it is easier to
                    read the build file because it uses its own DSL (Domain Specific Language) which are Groovy and
                    Kotlin based as opposed to pure XML used by Maven.
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
            <p>
                This page explains Gradle using a simple program using Java.
            </p>
        </Container>
    ;

    const RenderSubTopic = () =>
        <SubTopic
            subTopicName={subTopic}
            ThisPageDiv={ThisPageDiv}
            Steps={StepsGradle}
        />
    ;

    return (
        <>
            <BreadCrumbsNavigation/>
            <RenderTitle titleOfThePage={subTopic}/>
            <RenderSubTopic/>
        </>
    );
};

export default Gradle;
