import {memo} from 'react';
import {
    CodeBlockContainer,
    StepHeading,
    SubTopicsStepsTemplate
} from '../../TopicTemplates/SubTopicStepsTemplate';
import {UlWithLeftPaddingZero, LiWithVerticalPaddingTwo} from "../../TopicTemplates/SubTopicTemplate";

const StepsStartingWithReactJS = () => {

    const Step1 =
        <LiWithVerticalPaddingTwo key={'step1'}>
            <StepHeading>Install Node JS</StepHeading>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    Node JS is required for Javascript runtime and brings along a host of Javascript packages
                    along with it like the npm (Node package manager).
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    Install Node JS from the <a href={'https://nodejs.org/'}>download page</a>
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    Verify the installation by using the following command in the Command line or Terminal
                    <CodeBlockContainer>
                        node --version
                    </CodeBlockContainer>
                    The above command should output the version of Node JS that was installed.
                    Sample output:
                    <CodeBlockContainer>
                        v12.16.3
                    </CodeBlockContainer>
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
        </LiWithVerticalPaddingTwo>
    ;

    const Step2 =
        <LiWithVerticalPaddingTwo key={'step2'}>
            <StepHeading>Create React app using a template</StepHeading>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    A React app can be created from a template using <code>npx</code> command line tool which comes
                    along with Node JS installation.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    Open command line and type the following
                    <CodeBlockContainer>npx create-react-app my-app</CodeBlockContainer>
                    <ol>
                        <LiWithVerticalPaddingTwo>
                            Here, 'npx' is a command-line utility that
                            downloads and installs 'create-react-app' template from npm registry.
                        </LiWithVerticalPaddingTwo>
                        <LiWithVerticalPaddingTwo>
                            'my-app' is the name of the directory where all the downloaded files and directories will be
                            present. Any name can be specified for the directory.
                        </LiWithVerticalPaddingTwo>
                        <LiWithVerticalPaddingTwo>
                            Read more about the create-react-app <a
                            href={'https://github.com/facebook/create-react-app'}>here</a>
                        </LiWithVerticalPaddingTwo>
                    </ol>
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
        </LiWithVerticalPaddingTwo>
    ;

    const Step3 =
        <LiWithVerticalPaddingTwo key={'step3'}>
            <StepHeading>Directory structure</StepHeading>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    The 'my-app' directory contains directories and files. The ones to focus are 'public' and 'src'
                    directories and the package.json file.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    The <strong>public</strong> directory contains a index.html file which is the HTML base file where
                    the React components will be injected into.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    The <strong>src</strong> directory contains Javascript files which are used to build components and
                    inject them into the 'index.html' file in the 'public' directory.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    The <strong>package.json</strong> file contains information about the React app. The dependencies
                    section which declare various Javascript libraries required to build and start a React app.
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
        </LiWithVerticalPaddingTwo>
    ;

    const Step4 =
        <LiWithVerticalPaddingTwo key={'step4'}>
            <StepHeading>render from react-dom library</StepHeading>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    The <strong>index.js</strong> file contains <code>createRoot</code> method imported from
                    'react-dom' dependency. How to use it:
                    <ol>
                        <LiWithVerticalPaddingTwo>
                            Create a HTMLElement type using <code>document.querySelector</code> or <code>
                            document.getElementById</code> with the node from the HTML index page(in directory
                            public/index.html)
                        </LiWithVerticalPaddingTwo>
                        <CodeBlockContainer>
                            const root = createRoot(document.getElementById('root'));
                        </CodeBlockContainer>
                        <LiWithVerticalPaddingTwo>
                            Use the render method on the HTMLElement type created above with the starter JSX
                            component(E.g, App.js denoted as {"<App/>"} below)
                        </LiWithVerticalPaddingTwo>
                        <CodeBlockContainer>
                            {"root.render(<App/>)"}
                        </CodeBlockContainer>
                    </ol>
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
        </LiWithVerticalPaddingTwo>
    ;

    const Step5 =
        <LiWithVerticalPaddingTwo key={'step5'}>
            <StepHeading>
                Component - App.js
            </StepHeading>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    The App.js file contains a function - <strong>App()</strong> which is a component and returns a
                    HTML like format called JSX (JavaScript XML).
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    This component which is exported is used by index.js and injects the App component into index.html
                    file.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    Additional components can be added to increase modularity which results in easy maintenance.
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
        </LiWithVerticalPaddingTwo>
    ;

    const Step6 =
        <LiWithVerticalPaddingTwo key={'step6'}>
            <StepHeading>Simple React based website</StepHeading>
            <UlWithLeftPaddingZero>
                Have a look at a simple React based website in my GitHub repository - <a
                href={'https://github.com/neonidian/simple-time-app-react'}>A simple time app</a>
            </UlWithLeftPaddingZero>
        </LiWithVerticalPaddingTwo>
    ;

    const SummingUp =
        <p>
            This page describes about React Javascript framework and helps to start with designing web pages.
        </p>
    ;

    const RenderSteps = () =>
        <SubTopicsStepsTemplate
            stepsArray={[Step1, Step2, Step3, Step4, Step5, Step6]}
            SummingUp={SummingUp}
        />
    ;

    return (
        <RenderSteps/>
    );
}

export default memo(StepsStartingWithReactJS);
