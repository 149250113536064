import {topicsJsonData} from './topicsJsonData';

const calculateCurrentRouteWithMainTopicOnly = (mainTopic) =>
    topicsJsonData.find(aTopic =>
        aTopic.mainTopic === mainTopic
    ).topicUrl;

const calculateCurrentRouteWithMainAndSubTopics = (mainTopic, subTopic) =>
    topicsJsonData.find(aTopic =>
        aTopic.mainTopic === mainTopic
    ).subTopics.find(aSubTopic =>
        aSubTopic.subTopicName === subTopic
    ).subTopicUrl;

export {calculateCurrentRouteWithMainTopicOnly, calculateCurrentRouteWithMainAndSubTopics};
