import {Breadcrumb, Container} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import {notes} from './resources/topicsJsonData';
import RenderNavigationBarSubTopic from './NavigationBarSubComponents/RenderNavigationBarSubTopic';
import RenderNavigationBarMainTopic from './NavigationBarSubComponents/RenderNavigationBarMainTopic';

const BreadCrumbsNavigation = () => {

    const currentPath = useLocation().pathname;
    const pathLength = currentPath.split('/').length;

    const NotesNavigation = ({isActive}) =>
        <Breadcrumb.Item
            href={notes.notesUrl}
            active={!!isActive}>
            {notes.notesText}
        </Breadcrumb.Item>
    ;

    const ShowNotesOnly = () =>
        <Breadcrumb>
            <NotesNavigation isActive={true}/>
        </Breadcrumb>
    ;

    const ShowNotesAndMainTopic = () =>
        <Breadcrumb>
            <NotesNavigation isActive={false}/>
            <RenderNavigationBarMainTopic currentPath={currentPath} isSubTopicsIncluded={false}/>
        </Breadcrumb>
    ;

    const ShowNotesMainTopicAndSubTopic = () =>
        <Breadcrumb>
            <NotesNavigation isActive={false}/>
            <RenderNavigationBarMainTopic currentPath={currentPath} isSubTopicsIncluded={true}/>
            <RenderNavigationBarSubTopic currentPath={currentPath}/>
        </Breadcrumb>
    ;

    const RenderBreadCrumbs = () => {
        if (pathLength === 2) {
            return <ShowNotesOnly/>;
        } else if (pathLength === 3) {
            return <ShowNotesAndMainTopic/>;
        } else if (pathLength === 4) {
            return <ShowNotesMainTopicAndSubTopic/>;
        } else {
            return null;
        }
    };

    return (
        <Container id={'notes-navigation-bar'}>
            <RenderBreadCrumbs/>
        </Container>
    );
};

export default BreadCrumbsNavigation;
