import {Helmet} from 'react-helmet';

const RenderTitle = ({titleOfThePage}) =>
    <Helmet>
        <title>
            {`${titleOfThePage} | Keerthi`}
        </title>
    </Helmet>
;

export default RenderTitle;

RenderTitle.defaultProps = {
    titleOfThePage: 'All about software'
};
