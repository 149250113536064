import {topicsJsonData} from '../resources/topicsJsonData';
import {Breadcrumb} from 'react-bootstrap';
import PropTypes from 'prop-types';

const RenderNavigationBarSubTopic = ({currentPath}) => {
    let subTopicName = '';

    topicsJsonData.forEach(aTopic => {
            const message = aTopic.subTopics.find(aSubTopic => aSubTopic.subTopicUrl === currentPath);
            if (message !== undefined) {
                subTopicName = message.subTopicName;
                return subTopicName;
            }
        }
    );

    return (
        <Breadcrumb.Item
            href={currentPath}
            active
        >
            {subTopicName}
        </Breadcrumb.Item>
    );
};

export default RenderNavigationBarSubTopic;

RenderNavigationBarSubTopic.propTypes = {
    currentPath: PropTypes.string.isRequired
};
