import {Container} from 'react-bootstrap';
import PropTypes from 'prop-types';

const SubTopic = ({subTopicName, ThisPageDiv, Steps}) => {

    const PageHeading = () =>
        <h2 className={'text-primary'}>
            {subTopicName}
        </h2>
    ;

    const StepsHeading = () =>
        <h2 className={'text-primary h3'}>Steps</h2>
    ;

    return (
        <Container as={'main'}>
            <PageHeading/>
            <ThisPageDiv/>
            <hr/>
            <StepsHeading/>
            <Steps/>
        </Container>
    );
};

const UlWithLeftPaddingZero = ({children}) =>
    <ul className={'pl-0'}>
        {children}
    </ul>;

const LiWithVerticalPaddingTwo = ({children}) =>
    <li className={'py-2'}>
        {children}
    </li>;

export {SubTopic, UlWithLeftPaddingZero, LiWithVerticalPaddingTwo};

SubTopic.propTypes = {
    subTopicName: PropTypes.string.isRequired
};
