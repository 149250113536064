import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Notes from './components/Notes/Notes';
import Footer from './components/Footer/Footer';
import RenderTitle from './customHooks/RenderTitle';
import NotFoundPage from "./components/NotFoundPage/NotFoundPage";
import {
    javaMainTopicUrl, javascriptMainTopicUrl, javascriptSubTopicUrl1,
    javaSubTopicUrl1,
    javaSubTopicUrl2,
    notes,
    topicsJsonData,
} from "./components/Notes/resources/topicsJsonData";
import Java from "./components/Notes/NotesComponents/JavaTopic/Java";
import Javascript from "./components/Notes/NotesComponents/JavascriptTopic/Javascript";
import StartingWithJava from "./components/Notes/NotesComponents/JavaTopic/StartingWithJava/StartingWithJava";
import Gradle from "./components/Notes/NotesComponents/JavaTopic/Gradle/Gradle";
import StartingWithReactJS
    from "./components/Notes/NotesComponents/JavascriptTopic/StartingWithReact/StartingWithReactJS";

const App = () =>
    // TODO: Change BrowserRouter: https://reactrouter.com/en/main/routers/picking-a-router
    <BrowserRouter>
        <RenderTitle/>
        <Header/>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path={notes.notesUrl}>
                <Route index element={<Notes />} />
                <Route path={javaMainTopicUrl}>
                    <Route index element={<Java />} />
                    <Route path={javaSubTopicUrl1} element={<StartingWithJava />} />
                    <Route path={javaSubTopicUrl2} element={<Gradle />} />
                </Route>
                <Route path={javascriptMainTopicUrl}>
                    <Route index element={<Javascript />} />
                    <Route path={javascriptSubTopicUrl1} element={<StartingWithReactJS />} />
                </Route>
            </Route>
            <Route path={"*"} element={<NotFoundPage/>}/>
        </Routes>
        <Footer/>
    </BrowserRouter>;

export default App;
