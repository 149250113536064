import { useState } from 'react';
import {Container, Figure} from 'react-bootstrap';
import FigureImage from 'react-bootstrap/FigureImage';
import FigureCaption from 'react-bootstrap/FigureCaption';
import myImage from '../resources/keerthi-sunset-showoff.jpg';
import LinesAboutMe from './AboutMeComponents/LinesAboutMe';
import MergedLinkedInAndGitHubLogos from './AboutMeComponents/MergeLinkedInAndGitHubLogos';

const AboutMe = () => {

    const [isImageLoaded, setImageLoaded] = useState(false);
    const FullName = () => <h2 className={'text-center'}>Hi! I am Keerthi Shankar Subramanian</h2>;

    const MyPic = () =>
        <Figure
            style={isImageLoaded ? {} : {display: 'none'}}
        >
            <FigureImage
                src={myImage}
                alt={'Pic of Keerthi'}
                thumbnail={true}
                className={'border-secondary rounded-pill'}
                onLoad={() => setImageLoaded(true)}
            />
            <FigureCaption className={'text-center'}>
                Trouville beach, France.<br/>
                Photo credit: Joffry Mayol Ferrater
            </FigureCaption>
        </Figure>
    ;

    return (
        <Container id={'about-me-section'}>
            <FullName/>
            <LinesAboutMe/>
            <MergedLinkedInAndGitHubLogos/>
            <MyPic/>
        </Container>
    );
};

export default AboutMe;
