import {Col, Container, Row} from 'react-bootstrap';

const RenderTopLevelDescription = () =>
    <Container>
        <Row>
            <Col>
                <h2 className={'text-center text-primary py-3'}>
                    Tech topics
                </h2>
                <hr />
            </Col>
        </Row>
    </Container>;

export default RenderTopLevelDescription;
