import {Col, Container, Row} from 'react-bootstrap';
import {topicsJsonData} from '../resources/topicsJsonData';
import RenderCloudSvg from './RenderTopicsSubComponents/RenderCloudSvg';
import RenderSubTopics from './RenderTopicsSubComponents/RenderSubTopics';

const RenderMainTopics = () => {

    const RenderTopicsInClouds = ({aTopic}) =>
        <Container className={'py-3 text-center'}>
            <Row>
                <Col lg={6} md={6}>
                    <RenderCloudSvg topicUrl={aTopic.topicUrl} mainTopic={aTopic.mainTopic}/>
                </Col>
                <Col lg={2} md={2}>
                    <RenderSubTopics subTopics={aTopic.subTopics}/>
                </Col>
            </Row>
            <hr />
        </Container>
    ;

    return topicsJsonData.map(aTopic => <RenderTopicsInClouds aTopic={aTopic} key={aTopic.id}/>);
};

export default RenderMainTopics;
