import { useRef, useState } from 'react';
import {Overlay, Tooltip} from 'react-bootstrap';

export const useToolTip = () => {

    const [showToolTip, setShowToolTip] = useState(false);

    const svgRef = useRef(null);

    const showToolTipDisplay = () => setShowToolTip(true);

    const hideToolTipDisplay = () => setShowToolTip(false);

    const HeaderToolTip = (toolTipId, toolTipMessageToDisplay) =>
        <Overlay target={svgRef.current} show={showToolTip} placement={'bottom'}>
            {
                props =>
                    <Tooltip id={toolTipId} {...props}>
                        {toolTipMessageToDisplay}
                    </Tooltip>
            }
        </Overlay>
    ;

    return {showToolTipDisplay, hideToolTipDisplay, HeaderToolTip, svgRef};
};
