import {Container} from "react-bootstrap";

const NotFoundPage = () =>
    <>
        <Container>
            That didn't seem right 🤔 You seem to have found a non-existing page!
        </Container>
        <Container>
            Click <a href={'/'}>here</a> to go to home page
        </Container>
    </>

export default NotFoundPage;
