import {Col, Container, Row} from 'react-bootstrap';

const Footer = () => {

    const RenderFooter = () =>
        <footer className={'position-absolute fixed-bottom navbar-fixed-bottom'}>
            <Container className={'py-2 text-center text-muted'}>
                <Row>
                    <Col>
                        © Keerthi Shankar Subramanian. All rights reserved.
                    </Col>
                </Row>
            </Container>
        </footer>
    ;

    return (
        <RenderFooter/>
    );
};

export default Footer;
