import PropTypes from 'prop-types';

const RenderSubTopics = ({subTopics}) => {

    const TableRow = () => subTopics.map(aSubTopic =>
        <tr key={aSubTopic.subTopicName}>
            <td>
                <a href={aSubTopic.subTopicUrl}>
                    <strong>
                        <li>{aSubTopic.subTopicName}</li>
                    </strong>
                </a>
            </td>
        </tr>
    );

    const TableBody = () =>
        <tbody>
        <TableRow/>
        </tbody>;

    return (
        <table style={{height: '100%', width: '100%'}}>
            <TableBody/>
        </table>
    );
};

export default RenderSubTopics;

RenderSubTopics.propTypes = {
    subTopics: PropTypes.arrayOf(PropTypes.exact(
        {
            subTopicName: PropTypes.string.isRequired,
            subTopicUrl: PropTypes.string.isRequired
        }
    ))
};
