import {Table} from 'react-bootstrap';
import PropTypes from 'prop-types';

const TableOfContents = ({subTopics}) => {

    const RenderSubTopicsInTableOfContents = () => subTopics.map(aSubTopic =>
        <tr key={aSubTopic.subTopicName}>
            <td>
                <li>
                    <strong>
                        <a href={aSubTopic.subTopicUrl}>
                            {aSubTopic.subTopicName}
                        </a>
                    </strong>
                </li>
            </td>
        </tr>
    );

    const TableBody = () =>
        <tbody>
        <RenderSubTopicsInTableOfContents/>
        </tbody>
    ;

    const TableHead = () =>
        <thead>
        <tr>
            <th className={'text-primary'}>
                <h3>
                    Topics
                </h3>
            </th>
        </tr>
        </thead>
    ;

    return (
        <Table borderless>
            <TableHead/>
            <TableBody/>
        </Table>
    );
};

export default TableOfContents;

TableOfContents.propTypes = {
    subTopics: PropTypes.arrayOf(
        PropTypes.exact(
            {
                subTopicName: PropTypes.string.isRequired,
                subTopicUrl: PropTypes.string.isRequired
            }
        )
    )
};
