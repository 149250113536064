import MainTopic from '../TopicTemplates/MainTopicTemplate';
import {topicNames, topicsJsonData} from '../../resources/topicsJsonData';
import {calculateCurrentRouteWithMainTopicOnly} from '../../resources/calculateCurrentRoute';
import RenderTitle from '../../../../customHooks/RenderTitle';
import BreadCrumbsNavigation from "../../BreadCrumbsNavigation";

const Java = () => {

    const mainTopic = topicNames.java.mainTopic;

    const subTopics = topicsJsonData.find(aTopic => aTopic.mainTopic === mainTopic).subTopics;

    const currentRoute = calculateCurrentRouteWithMainTopicOnly(mainTopic);

    const ShortDescriptionAboutJava = () =>
        <div className={'text-justify'}>
            <p>
                A programming language used to give instructions to a computer on what you would like it to do. Java is
                used mainly in back-end programming.
            </p>
            <p>
                There are a few front-end Java frameworks like Vaadin used to design Web UIs.
            </p>
            <p>
                <strong className={'text-primary'}>Application</strong> -
                On opening an Android app on your phone, a program like Java runs in the background to instruct the
                phone's CPU(Central processing unit) to display the app on the screen.
            </p>
        </div>
    ;

    const RenderMainTopic = () =>
        <MainTopic
            mainTopic={mainTopic}
            ShortDescriptionDiv={ShortDescriptionAboutJava}
            subTopics={subTopics}
        />
    ;

    const SwitchRouteForJavaTopic = () =>
        <>
            <BreadCrumbsNavigation/>
            <RenderTitle titleOfThePage={mainTopic}/>
            <RenderMainTopic/>
        </>
    ;

    return (
        <>
            <SwitchRouteForJavaTopic/>
        </>
    );
};

export default Java;
