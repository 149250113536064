import {Container} from 'react-bootstrap';
import {topicNames} from '../../../resources/topicsJsonData';
import {calculateCurrentRouteWithMainAndSubTopics} from '../../../resources/calculateCurrentRoute';
import {LiWithVerticalPaddingTwo, SubTopic, UlWithLeftPaddingZero} from '../../TopicTemplates/SubTopicTemplate';
import StepsStartingWithReactJS from './StepsStartingWithReactJS';
import RenderTitle from '../../../../../customHooks/RenderTitle';
import BreadCrumbsNavigation from "../../../BreadCrumbsNavigation";

const StartingWithReactJS = () => {

    const mainTopic = topicNames.javascript.mainTopic;

    const subTopic = topicNames.javascript.subTopics.startingWithReact;

    const currentRoute = calculateCurrentRouteWithMainAndSubTopics(mainTopic, subTopic);

    const ThisPageDiv = () =>
        <Container className={'text-justify'}>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    <a href={'https://reactjs.org/'}>React JS</a> is a framework based on Javascript programming
                    language to design UI (User Interface) web pages. Websites like Facebook and Instagram use React JS.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    The framework uses components to render UIs which makes the development process easier. A component
                    can be a header in a page. Another component that renders the body and so on. Several components can
                    be merged to form a web page.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    Note that this website is designed using React JS.
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
            <p>
                This page helps you start with creating a web page using React JS.
            </p>
        </Container>
    ;

    const RenderSubTopic = () =>
        <SubTopic
            subTopicName={subTopic}
            ThisPageDiv={ThisPageDiv}
            Steps={StepsStartingWithReactJS}
        />
    ;

    return (
        <>
            <BreadCrumbsNavigation/>
            <RenderTitle titleOfThePage={subTopic}/>
            <RenderSubTopic/>
        </>
    );
};

export default StartingWithReactJS;
