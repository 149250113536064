import {Accordion, Card} from "react-bootstrap"

const Nordstrom = () => {

    const Heading = () => <h4 className={'fst-italic'}>Nordstrom</h4>

    const AboutTheCompany = () => <p>Luxury fashion retail company based in the U.S., sells clothing, footwear, handbags
        and more.</p>

    const Role = () =>
        <div>
            <h5>Role</h5>
            <p>Test Engineer</p>
        </div>

    const Duration = () =>
        <div>
            <h5>Duration</h5>
            <p>2012 to 2015</p>
        </div>

    const ContributionHeading = () => <h5>Key contributions</h5>
    const ContributionDescription = () =>
        <ul>
            <li>
                Part of the website scrum team to plan and execute automated and manual test cases.
            </li>
            <li>
                Worked as a consultant from Infosys.
            </li>
            <li>
                Used TDD(Test Driven Development) approach to software development.
            </li>
            <li>
                Involved with creating Selenium framework, creating Selenium tests.
            </li>
            <li>
                Tools used: Selenium with C#, Jenkins, Powershell, Visual Studio, SOAP UI, SQL, HP Quality Center, JIRA,
                Locust
            </li>
        </ul>

    const Contribution = () =>
        <div>
            <ContributionHeading/>
            <ContributionDescription/>
        </div>

    const uniqueKey = "1"

    return (
        <Card className={'border-top border-left border-right border-danger'}>
            <Accordion.Item eventKey={uniqueKey}>
                <Accordion.Header as={Card.Header} eventKey={uniqueKey}>
                    <Heading/>
                </Accordion.Header>
                <Accordion.Collapse eventKey={uniqueKey}>
                    <Card.Body>
                        <AboutTheCompany/>
                        <Role/>
                        <Duration/>
                        <Contribution/>
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion.Item>
        </Card>
    )
}

export default Nordstrom
