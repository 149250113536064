import { memo } from 'react';
import {
    CodeBlockContainer, RowInACodeBlock,
    StepHeading,
    SubTopicsStepsTemplate
} from '../../TopicTemplates/SubTopicStepsTemplate';
import {Container, Row} from 'react-bootstrap';
import {LiWithVerticalPaddingTwo, UlWithLeftPaddingZero} from "../../TopicTemplates/SubTopicTemplate";

const StepsGradle = () => {

    const Step1 =
        <LiWithVerticalPaddingTwo key={'step1'}>
            <StepHeading>Install Gradle</StepHeading>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    Go to the <a href={'https://gradle.org/install/'}>install</a> page and follow the instructions.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    If you are using Bash or Zsh, Gradle provides a command-line completion tool <a
                    href={'https://github.com/gradle/gradle-completion'}>here</a>. How to use command-line completion
                    see <a
                    href={'https://docs.gradle.org/current/userguide/command_line_interface.html#sec:command_line_completion'}>
                    here
                </a>
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    After successful installation, open command-line and type the following to verify the installation
                    <CodeBlockContainer>
                        gradle --version
                    </CodeBlockContainer>
                    The output of the above command should be information about the Gradle version that is installed.
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
        </LiWithVerticalPaddingTwo>
    ;

    const Step2 =
        <LiWithVerticalPaddingTwo key={'step2'}>
            <StepHeading>Create a Java console app</StepHeading>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    Using the command-line, create and go the directory (e.g., my-java-app) where you would like to
                    create a new Java app with Gradle as the build system.
                    <CodeBlockContainer>
                        <RowInACodeBlock>mkdir my-java-app</RowInACodeBlock>
                        <RowInACodeBlock>cd my-java-app</RowInACodeBlock>
                    </CodeBlockContainer>
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    Inside the new directory run,
                    <CodeBlockContainer>
                        gradle init
                    </CodeBlockContainer>
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    When prompted to select the project type, type '2' to select 'application' and press <kbd>Enter
                </kbd> key.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    For the next questions, press <kbd>Enter</kbd> key to use the default values. All the default
                    values would correspond to creating a Java application.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    A list of files and directories are created by Gradle.
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
        </LiWithVerticalPaddingTwo>
    ;

    const Step3 =
        <LiWithVerticalPaddingTwo key={'step3'}>
            <StepHeading>Understanding the contents of the directory</StepHeading>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    <strong>build.gradle</strong> file contains the configuration required to build and run the Java
                    application in the directory. <p className={'pt-2'}>In the plugins block which has <code>id 'java'
                    </code> denotes that the current directory is Java based and is used by Gradle to perform Java
                    specific tasks like using a Java compiler for building the source files.</p>
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    <strong>gradle</strong> directory
                    and <strong>gradlew</strong> and <strong>gradlew.bat</strong> files are for the purpose of wrapping
                    Gradle with a specific version so that using these files give the same output across different
                    operating systems independent of the version of Gradle that is installed in the host operating
                    system.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    <strong>src/</strong> directory is created by Gradle as per Java convention.
                    <p className={'pt-2'}>
                        Source files - src/main/java
                    </p>
                    <p>
                        Test files - src/test/java
                    </p>
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    A sample Java file - <strong>App.java</strong> in src/main/java/gradle/ directory is created.
                    Running this Java app, would output 'Hello world.' in the console.
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
        </LiWithVerticalPaddingTwo>
    ;

    const Step4 =
        <LiWithVerticalPaddingTwo key={'step4'}>
            <StepHeading>
                Building and running the Java app
            </StepHeading>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    Open command-line and go to the directory containing the Java app and type the following to run the
                    Java app
                    <CodeBlockContainer>
                        gradle run
                    </CodeBlockContainer>
                    Sample output:
                    <CodeBlockContainer>
                        <Container>
                            <Row>
                                > Task :run
                            </Row>
                            <Row>
                                Hello world.
                            </Row>
                        </Container>
                    </CodeBlockContainer>
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    An alternative to using <code>gradle</code> command is using the Gradle wrapper file - <code>
                    gradlew</code> in the command line. To use this, go to the project's directory and type the
                    following to get the same output as above.
                    <CodeBlockContainer>
                        ./gradlew run
                    </CodeBlockContainer>
                    For Windows OS, use
                    <CodeBlockContainer>
                        gradlew run
                    </CodeBlockContainer>
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
        </LiWithVerticalPaddingTwo>
    ;

    const Step5 =
        <LiWithVerticalPaddingTwo key={'step5'}>
            <StepHeading>Understanding how Gradle works</StepHeading>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    Gradle performs a series of steps which it calls 'Tasks' to build and run a app.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    In the build.gradle file, using the Java plugin would pull in a number of tasks for compiling and
                    running Java.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    A task can depend on another task which means before a task runs, it makes other tasks run.
                    For example, compiling a Java source code is required before running it.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    In our example, <code>run</code> is a task and to see the other tasks before it executes, use
                    the <code>--dry-run</code> flag. Introducing this flag does not perform acutal execution but does a
                    dry-run and displays what tasks would be executed. Example snippet:
                    <CodeBlockContainer>
                        gradle --dry-run run
                    </CodeBlockContainer>
                    Sample output:
                    <CodeBlockContainer>
                        <RowInACodeBlock>:compileJava SKIPPED</RowInACodeBlock>
                        <RowInACodeBlock>:processResources SKIPPED</RowInACodeBlock>
                        <RowInACodeBlock>:classes SKIPPED</RowInACodeBlock>
                        <RowInACodeBlock>:run SKIPPED</RowInACodeBlock>
                    </CodeBlockContainer>
                    The above output shows that before the <code>run</code> task is executed, 3 other tasks will be
                    executed in that order.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    Running only the <code>compileJava</code> task would create a <strong>build</strong> directory and
                    generate the Java byte code there. Try this out by deleting the <strong>build</strong> directory and
                    running the <code>compileJava</code> task like below:
                    <CodeBlockContainer>
                        gradle compileJava
                    </CodeBlockContainer>
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    Gradle uses a daemon to not repeat a task. For example, when executing <code>run</code> task, Gradle
                    only executes <code>compileJava</code> once and for subsequent executions, Gradle would re-use the
                    previous output provided the source code has not changed. If the source code has changed, Gradle
                    re-compiles using the <code>compileJava</code> task and then executes the <code>run</code> task.
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
        </LiWithVerticalPaddingTwo>
    ;

    const Step6  =
        <LiWithVerticalPaddingTwo key={'step6'}>
            <StepHeading>A sample Gradle project</StepHeading>
            <p>
                Our example above uses Groovy DSL. For Gradle with Kotlin DSL, have a look at my Java project
                in <a href={'https://github.com/neonidian/selenium-java-page-object-model'}>GitHub</a>. Note that the
                build file has extension .kts which denotes Kotlin DSL is being used.
            </p>
        </LiWithVerticalPaddingTwo>
    ;

    const SummingUp =
        <p>
            Gradle is a build tool that has its own DSL which is easy to read and using it increases productivity.
        </p>
    ;

    const RenderSteps = () =>
        <SubTopicsStepsTemplate
            stepsArray={[Step1, Step2, Step3, Step4, Step5, Step6]}
            SummingUp={SummingUp}
        />
    ;

    return (
        <RenderSteps/>
    );
};

export default memo(StepsGradle);
