import {topicsJsonData} from '../resources/topicsJsonData';
import {Breadcrumb} from 'react-bootstrap';
import PropTypes from 'prop-types';

const RenderNavigationBarMainTopic = ({currentPath, isSubTopicsIncluded}) => {

    const findMainTopic = () => {

        const pathSeparator = '/';

        if (isSubTopicsIncluded) {
            const splitCurrentPath = currentPath.split(pathSeparator);
            splitCurrentPath.pop();
            const mergePathAfterPop = splitCurrentPath.join(pathSeparator);
            const mainTopic = topicsJsonData.find(aTopic => aTopic.topicUrl === mergePathAfterPop).mainTopic;
            return {mainTopic, path: mergePathAfterPop};
        } else {
            const mainTopic = topicsJsonData.find(aTopic => aTopic.topicUrl === currentPath).mainTopic;
            return {mainTopic, path: currentPath};
        }
    };

    return (
        <Breadcrumb.Item
            active={isSubTopicsIncluded ? '' : 'active'}
            href={findMainTopic().path}
        >
            {findMainTopic().mainTopic}
        </Breadcrumb.Item>
    );
};

export default RenderNavigationBarMainTopic;

RenderNavigationBarMainTopic.propTypes = {
    currentPath: PropTypes.string.isRequired,
    isSubTopicsIncluded: PropTypes.bool.isRequired
};
