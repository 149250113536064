const notes = {
    notesText: 'Notes',
    notesUrl: '/notes'
};

const topicNames = {
    java: {
        mainTopic: 'Java',
        subTopics: {
            startingWithJava: 'Starting with Java',
            gradle: 'Gradle'
        }
    },
    javascript: {
        mainTopic: 'Javascript',
        subTopics: {
            startingWithReact: 'Starting with React JS'
        }
    }
};

// TODO: Refactor below lines: put in a common object
const javaMainTopicUrl = 'java';
const javaSubTopicUrl1 = 'starting-with-java'
const javaSubTopicUrl2 = 'gradle'
const javascriptMainTopicUrl = 'javascript';
const javascriptSubTopicUrl1 = 'starting-with-react'


const topicsJsonData = [
    {
        id: '1',
        mainTopic: topicNames.java.mainTopic,
        topicUrl: `${notes.notesUrl}/${javaMainTopicUrl}`,
        subTopics: [
            {
                subTopicName: topicNames.java.subTopics.startingWithJava,
                subTopicUrl: `${notes.notesUrl}/${javaMainTopicUrl}/${javaSubTopicUrl1}`
            },
            {
                subTopicName: topicNames.java.subTopics.gradle,
                subTopicUrl: `${notes.notesUrl}/${javaMainTopicUrl}/${javaSubTopicUrl2}`
            }
        ]
    },
    {
        id: '2',
        mainTopic: topicNames.javascript.mainTopic,
        topicUrl: `${notes.notesUrl}/${javascriptMainTopicUrl}`,
        subTopics: [
            {
                subTopicName: topicNames.javascript.subTopics.startingWithReact,
                subTopicUrl: `${notes.notesUrl}/${javascriptMainTopicUrl}/${javascriptSubTopicUrl1}`
            }
        ]
    }
];

export {
    notes,
    topicNames,
    topicsJsonData,
    javaMainTopicUrl,
    javascriptMainTopicUrl,
    javaSubTopicUrl1,
    javaSubTopicUrl2,
    javascriptSubTopicUrl1,
};
