import PropTypes from 'prop-types';

const RenderCloudSvg = ({topicUrl, mainTopic}) =>
    <svg xmlns={'http://www.w3.org/2000/svg'}
         role={'img'}
         aria-label={`An image for ${mainTopic} topic`}
         viewBox={'0 0 105 105'}
         width={'190'}
         height={'190'}
         fill={'#e7e7e7'}
    >
        <path transform={'translate(0, -15.674)'}
              fill={'rgb(233, 240, 247)'}
              d={'M 25,60 a 20,20 1 0,0 0,40 h 50 a 20,20 1 0,0 0,-40 a 10,10 1 0,0 -15,-10 a 15,15 1 0,0 -35,10 z'}
        />
        <a href={topicUrl}>
            <text x={'48%'}
                  y={'55%'}
                  textAnchor={'middle'}
                  fill={'#007bff'}
                  dy={'.4em'}
                  textDecoration={'underline'}
                  fontSize={'12'}>
                {mainTopic}
            </text>
        </a>
    </svg>;

export default RenderCloudSvg;

RenderCloudSvg.propTypes = {
    topicUrl: PropTypes.string.isRequired,
    mainTopic: PropTypes.string.isRequired
};
