import {Container} from 'react-bootstrap';
import AboutMe from './HomeComponents/AboutMe';
import RenderTitle from '../../customHooks/RenderTitle';
import WorkHistory from "./HomeComponents/WorkHistory/WorkHistory";
import Spotify from "./HomeComponents/Spotify";

const Home = () => {
    const homePageTitle = 'Software engineer';

    return (
        <>
            <RenderTitle titleOfThePage={homePageTitle}/>
            <Container as={'main'} id={'home-page'}>
                <AboutMe/>
                <hr className={'border-dark'}/>
                <WorkHistory/>
                <Spotify/>
            </Container>
        </>
    );
};

export default Home;
