import {Container} from 'react-bootstrap';
import {topicNames} from '../../../resources/topicsJsonData';
import {calculateCurrentRouteWithMainAndSubTopics} from '../../../resources/calculateCurrentRoute';
import {LiWithVerticalPaddingTwo, SubTopic, UlWithLeftPaddingZero} from '../../TopicTemplates/SubTopicTemplate';
import StepsStartingWithJava from './StepsStartingWithJava';
import RenderTitle from '../../../../../customHooks/RenderTitle';
import BreadCrumbsNavigation from "../../../BreadCrumbsNavigation";

const StartingWithJava = () => {

    const mainTopic = topicNames.java.mainTopic;

    const subTopic = topicNames.java.subTopics.startingWithJava;

    const currentRoute = calculateCurrentRouteWithMainAndSubTopics(mainTopic, subTopic);

    const ThisPageDiv = () =>
        <Container className={'text-justify'}>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    Computers understand only machine code(0's and 1's). So, a high-level programming language like
                    Java is needed because they are human readable.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    Every programming language has a way to convert a high level language to machine code like the JVM
                    (Java Virtual Machine) used by Java.
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
            <p>
                This page explains about how Java does the conversion from human readable to a
                machine readable code by writing a simple program.
            </p>
        </Container>
    ;

    const RenderSubTopic = () =>
        <SubTopic
            subTopicName={subTopic}
            ThisPageDiv={ThisPageDiv}
            Steps={StepsStartingWithJava}
        />
    ;

    return (
                <>
                    <BreadCrumbsNavigation/>
                    <RenderTitle titleOfThePage={subTopic}/>
                    <RenderSubTopic/>
                </>
    );
};

export default StartingWithJava;
