import { memo } from 'react';
import {Container, Row} from 'react-bootstrap';
import {
    CodeBlockContainer,
    RowInACodeBlock,
    StepHeading, SubTopicsStepsTemplate,
} from '../../TopicTemplates/SubTopicStepsTemplate';
import {UlWithLeftPaddingZero, LiWithVerticalPaddingTwo} from "../../TopicTemplates/SubTopicTemplate";

const StepsStartingWithJava = () => {

    const Step1 =
        <LiWithVerticalPaddingTwo key={'step1'}>
            <StepHeading>Installing Java</StepHeading>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    Java is owned by a company called Oracle and releases a specification from time to time on how a
                    functionality should work whenever a new version is released.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    There are companies that use this specification and release their own flavour of Java installable.
                    Here, we will be using OpenJDK which is a open source implementation of Java.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    We will be using version 11 of OpenJDK for this demo. Go to the <a
                    href={'https://openjdk.java.net/install/'}>
                    install</a> page and download JDK version 11 for your operating system and install it.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    Check if Java is installed successfully by opening a Command line and type <code>java
                    -version</code> and the output of this command should display information related to the version of
                    Java that was installed.
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
            <Container className={'py-3'}>
                <Row className={'px-3'}>
                    Example:
                </Row>
                <CodeBlockContainer>
                    <RowInACodeBlock>
                        keerthi@keerthi-ubuntu:~$ java -version
                    </RowInACodeBlock>
                    <RowInACodeBlock>
                        openjdk version "11.0.5" 2020-04-14
                    </RowInACodeBlock>
                    <RowInACodeBlock>
                        OpenJDK Runtime Environment
                    </RowInACodeBlock>
                    <RowInACodeBlock>
                        OpenJDK 64-Bit Server VM (build 11.0.5+11-post-Ubuntu-2ubuntu218.01, mixed mode, sharing)
                    </RowInACodeBlock>
                </CodeBlockContainer>
            </Container>
        </LiWithVerticalPaddingTwo>
    ;

    const Step2 =
        <LiWithVerticalPaddingTwo key={'step2'}>
            <StepHeading>A Text Editor</StepHeading>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    A text editor like the Notepad in Windows operating system is required for writing Java programs.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    Alternatively,
                    there are IDEs (Integrated Development Environment) like IntelliJ that can be used to write
                    Java programs as they help with syntax highlighting and a dozen of other features but here we will
                    be using a text editor.
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
        </LiWithVerticalPaddingTwo>
    ;

    const Step3 =
        <LiWithVerticalPaddingTwo key={'step3'}>
            <StepHeading>First program</StepHeading>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    Open any text editor and type the following program:
                    <CodeBlockContainer>
                        <RowInACodeBlock>
                            public class IntroductionToJava &#123;
                        </RowInACodeBlock>
                        <RowInACodeBlock>
                            &emsp;&emsp;public static void main(String[] args) &#123;
                        </RowInACodeBlock>
                        <RowInACodeBlock>
                            &emsp;&emsp;&emsp;&emsp;System.out.println("Hello Java!!");
                        </RowInACodeBlock>
                        <RowInACodeBlock>
                            &emsp;&emsp;&#125;
                        </RowInACodeBlock>
                        <RowInACodeBlock>
                            &#125;
                        </RowInACodeBlock>
                    </CodeBlockContainer>
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    Explanation of the above program:
                    <ol>
                        <LiWithVerticalPaddingTwo>
                            In Java, a <strong>class</strong> is the fundamental entity where a functionality is
                            defined. In the first line, 'class' is a keyword in Java that defines a class. 'public' is
                            also a keyword which tells Java that this piece of code can be accessed publicly from
                            another piece of code.
                        </LiWithVerticalPaddingTwo>
                        <LiWithVerticalPaddingTwo>
                            The second line is called a main method which is the entry-point for program execution. This
                            is a Java convention.
                        </LiWithVerticalPaddingTwo>
                        <LiWithVerticalPaddingTwo>
                            The third line which is enclosed in curly braces defines what the method should do. Here the
                            method outputs a text to the console.
                        </LiWithVerticalPaddingTwo>
                        <LiWithVerticalPaddingTwo>
                            The fourth and fifth line are the closing braces that encloses the method and the class
                            respectively and are required as per Java syntax.
                        </LiWithVerticalPaddingTwo>
                        <LiWithVerticalPaddingTwo>
                            Save the file as 'IntroductionToJava.java'. It is a good practise that the name of the
                            file and the class are the same.
                        </LiWithVerticalPaddingTwo>
                    </ol>
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
        </LiWithVerticalPaddingTwo>
    ;

    const Step4 =
        <LiWithVerticalPaddingTwo key={'step4'}>
            <StepHeading>Running the program</StepHeading>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    Open Command line and go to the directory where the 'IntroductionToJava.java' file has been
                    created in the previous step using <code>cd</code> command. E.g., In Linux OS, if the file is saved
                    in a directory named 'java' which is present in the home directory, use <code>cd $HOME/java</code>
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    To run the Java program, type the 'java' command followed by the name of the Java file
                    <CodeBlockContainer>java IntroductionToJava.java</CodeBlockContainer>
                    <u>Note</u>: The .java file extension is required to tell Java it is a Java file
                    <Row className={'pt-3'}>
                        Output:
                        <CodeBlockContainer>Hello Java!!</CodeBlockContainer>
                    </Row>
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
        </LiWithVerticalPaddingTwo>
    ;

    const Step5 =
        <LiWithVerticalPaddingTwo key={'step5'}>
            <StepHeading>How running the program works</StepHeading>
            <UlWithLeftPaddingZero>
                <LiWithVerticalPaddingTwo>
                    Java internally has a interpreter called the JVM(Java Virtual Machine) that translates a .java file
                    into a class file in-memory and uses the bytecode present in the class files to convert into machine
                    specific code and displays the results. This is done in-memory. So, there is no .class files
                    generated in the file system.
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    A class file with .class extension can also be generated using the 'javac' Java compile command. To
                    generate a class file for our example above, use the following snippet.
                    <CodeBlockContainer>
                        javac IntroductionToJava.java
                    </CodeBlockContainer>
                    The JVM would now act as a compiler and generate a class file named <code>IntroductionToJava.class
                    </code> which is the bytecode equivalent of our program which is not human readable. This file can
                    be executed using the 'java' command to get the same output - 'Hello Java!!'. Example:
                    <CodeBlockContainer>
                        java IntroductionToJava.class
                    </CodeBlockContainer>
                </LiWithVerticalPaddingTwo>
                <LiWithVerticalPaddingTwo>
                    The advantage of generating a class file is that this .class file can be copied to any Java
                    compliant OS and executing it will result in the same output.
                </LiWithVerticalPaddingTwo>
            </UlWithLeftPaddingZero>
        </LiWithVerticalPaddingTwo>
    ;

    const SummingUp =
        <p>
            How Java works is learnt through an example program.
        </p>
    ;

    const RenderSteps = () =>
        <SubTopicsStepsTemplate
            stepsArray={[Step1, Step2, Step3, Step4, Step5]}
            SummingUp={SummingUp}
        />
    ;

    return (
        <RenderSteps/>
    );
};

export default memo(StepsStartingWithJava);
