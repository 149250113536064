import {Accordion, Card} from "react-bootstrap"

const Ica = () => {

    const Heading = () => <h4 className={'fst-italic'}>ICA</h4>

    const AboutTheCompany = () =>
        <p>
            Swedish retail company with a focus on food and health though they also have presence in banking, pharmacy,
            real estate.
            Worked as a consultant from Infosys in the grocery website development team.
        </p>

    const Role = () =>
        <div>
            <h5>Role</h5>
            <p>Test Automation Engineer</p>
        </div>

    const Duration = () =>
        <div>
            <h5>Duration</h5>
            <p>2015 to 2017</p>
        </div>

    const ContributionHeading = () => <h5>Key contributions</h5>
    const ContributionDescription = () =>
        <ul>
            <li>
                Created test automation framework based on Selenium with Java, API tests.
            </li>
            <li>
                Reduced manual testing effort thereby decreasing delivery time for deployment to production environment.
            </li>
            <li>
                Mobile device automation using Appium, HP mobile center, Browser stack.
            </li>
            <li>
                Integrated test automation using TeamCity as part of continuous integration to provide faster feedback.
            </li>
            <li>
                Tools used: GitLab, Selenium with Java, TeamCity, Maven, TestNG, Eclipse, IntelliJ, Postman, SQL, JIRA,
                JavaScript, Nexus
            </li>
        </ul>

    const Contribution = () =>
        <div>
            <ContributionHeading/>
            <ContributionDescription/>
        </div>

    const uniqueKey = "2"

    return (
        <Card className={'border-top border-left border-right border-warning'}>
            <Accordion.Item eventKey={uniqueKey}>
                <Accordion.Header as={Card.Header} eventKey={uniqueKey}>
                    <Heading/>
                </Accordion.Header>
                <Accordion.Collapse eventKey={uniqueKey}>
                    <Card.Body>
                        <AboutTheCompany/>
                        <Role/>
                        <Duration/>
                        <Contribution/>
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion.Item>
        </Card>
)
}

export default Ica
