import {Container} from 'react-bootstrap';
import PropTypes from 'prop-types';
import TableOfContents from './TableOfContents';

const MainTopic = ({mainTopic, ShortDescriptionDiv, subTopics}) => {

    const PageHeading = () =>
        <h2 className={'text-primary'}>
            {mainTopic}
        </h2>
    ;

    return (
        <Container as={'main'} id={`${mainTopic}-main-topic-page`}>
            <PageHeading/>
            <ShortDescriptionDiv/>
            <hr/>
            <TableOfContents subTopics={subTopics}/>
        </Container>
    );
};

export default MainTopic;

MainTopic.propTypes = {
    mainTopic: PropTypes.string.isRequired,
    subTopics: PropTypes.arrayOf(PropTypes.object.isRequired)
};
