import RenderTopLevelDescription from './RenderTopLevelDescription';
import RenderMainTopics from './RenderMainTopics';

const RenderTopics = () =>
    <main id={'notes-page'}>
        <RenderTopLevelDescription/>
        <RenderMainTopics/>
    </main>;

export default RenderTopics;
