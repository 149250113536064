import {useToolTip} from '../../../../../customHooks/useToolTip';

const LinkedInLogo = () => {

    const {showToolTipDisplay, hideToolTipDisplay, HeaderToolTip, svgRef} = useToolTip();

    const LinkedInLogoSvg =
        <a href={'https://www.linkedin.com/in/keerthi-shankar-subramanian/'}
           target={"_blank"}
           rel={"noopener noreferrer"}
        >
            <svg xmlns="http://www.w3.org/2000/svg"
                 role={'img'}
                 aria-label={"Image link for Keerthi's LinkedIn page"}
                 viewBox={'0 0 64 64'}
                 width={'30px'}
                 height={'48px'}
                 ref={svgRef}
                 onMouseEnter={showToolTipDisplay}
                 onMouseLeave={hideToolTipDisplay}
                 onMouseDown={hideToolTipDisplay}
            >
                <radialGradient id="jAVUMfCM1liBjYZwQpghOa" cx="32" cy="31.5" r="31.259"
                                gradientUnits="userSpaceOnUse"
                                spreadMethod="reflect">
                    <stop offset="0" stopColor="#c5f1ff"/>
                    <stop offset=".35" stopColor="#cdf3ff"/>
                    <stop offset=".907" stopColor="#e4faff"/>
                    <stop offset="1" stopColor="#e9fbff"/>
                </radialGradient>
                <path fill="url(#jAVUMfCM1liBjYZwQpghOa)"
                      d="M58,54c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2h2.5c1.925,0,3.5-1.575,3.5-3.5 S62.425,43,60.5,43H50c-1.381,0-2.5-1.119-2.5-2.5c0-1.381,1.119-2.5,2.5-2.5h8c1.65,0,3-1.35,3-3c0-1.65-1.35-3-3-3H42v-6h18 c2.335,0,4.22-2.028,3.979-4.41C63.77,19.514,61.897,18,59.811,18H58c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2h0.357 c1.308,0,2.499-0.941,2.63-2.242C61.137,10.261,59.966,9,58.5,9h-14C43.672,9,43,8.328,43,7.5S43.672,6,44.5,6h3.857 c1.308,0,2.499-0.941,2.63-2.242C51.137,2.261,49.966,1,48.5,1L15.643,1c-1.308,0-2.499,0.941-2.63,2.242 C12.863,4.739,14.034,6,15.5,6H19c1.105,0,2,0.895,2,2c0,1.105-0.895,2-2,2H6.189c-2.086,0-3.958,1.514-4.168,3.59 C1.78,15.972,3.665,18,6,18h2.5c1.933,0,3.5,1.567,3.5,3.5c0,1.933-1.567,3.5-3.5,3.5H5.189c-2.086,0-3.958,1.514-4.168,3.59 C0.78,30.972,2.665,33,5,33h17v11H6c-1.65,0-3,1.35-3,3c0,1.65,1.35,3,3,3h0c1.105,0,2,0.895,2,2c0,1.105-0.895,2-2,2H4.189 c-2.086,0-3.958,1.514-4.168,3.59C-0.22,59.972,1.665,62,4,62h53.811c2.086,0,3.958-1.514,4.168-3.59C62.22,56.028,60.335,54,58,54z"
                />
                <linearGradient id="jAVUMfCM1liBjYZwQpghOb" x1="32" x2="32" y1="59.381" y2="15.381"
                                gradientUnits="userSpaceOnUse" spreadMethod="reflect">
                    <stop offset="0" stopColor="#155cde"/>
                    <stop offset=".278" stopColor="#1f7fe5"/>
                    <stop offset=".569" stopColor="#279ceb"/>
                    <stop offset=".82" stopColor="#2cafef"/>
                    <stop offset="1" stopColor="#2eb5f0"/>
                </linearGradient>
                <path fill="url(#jAVUMfCM1liBjYZwQpghOb)"
                      d="M50,12H14c-2.209,0-4,1.791-4,4v36c0,2.209,1.791,4,4,4h36c2.209,0,4-1.791,4-4V16 C54,13.791,52.209,12,50,12z"
                />
                <path fill="#fff"
                      d="M19 28h2c1.105 0 2 .895 2 2v17c0 1.105-.895 2-2 2h-2c-1.105 0-2-.895-2-2V30C17 28.895 17.895 28 19 28zM19.981 25h-.033C18.158 25 17 23.664 17 22c0-1.706 1.195-3 3.019-3 1.823 0 2.948 1.294 2.981 3C23 23.664 21.842 25 19.981 25zM45 49h-2c-1.105 0-2-.895-2-2v-9.372c0-2.749-1.506-4.624-4.239-4.624-1.953 0-3.133 1.265-3.626 2.485C32.954 35.929 33 37.14 33 37.75V47c0 1.105-.888 2-1.993 2H29c-1.105 0-2-.895-2-2V30c0-1.105.895-2 2-2h2.007c1.105 0 2 .895 2 2v1.021C33.911 29.625 35.564 28 39.173 28 43.646 28 47 30.563 47 36.842V47C47 48.105 46.105 49 45 49z"
                />
            </svg>
        </a>
    ;

    return (
        <>
            {LinkedInLogoSvg}
            {HeaderToolTip('linkedin-tool-tip', 'Visit my LinkedIn page')}
        </>
    );
};

export default LinkedInLogo;
