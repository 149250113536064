import LinkedInLogo from './GitHubAndLinkedInLogos/LinkedInLogo';
import GitHubLogo from './GitHubAndLinkedInLogos/GitHubLogo';

const MergedLinkedInAndGitHubLogos = () => {
    const Logos = () => <><LinkedInLogo/> <GitHubLogo/></>;

    const CaptionWithLogos = () => <p className={'text-center'}><em>Find me on <Logos/></em></p>;

    return <CaptionWithLogos/>;
};

export default MergedLinkedInAndGitHubLogos;
