import MainTopic from '../TopicTemplates/MainTopicTemplate';
import {topicNames, topicsJsonData} from '../../resources/topicsJsonData';
import {calculateCurrentRouteWithMainTopicOnly} from '../../resources/calculateCurrentRoute';
import RenderTitle from '../../../../customHooks/RenderTitle';
import BreadCrumbsNavigation from "../../BreadCrumbsNavigation";

const Javascript = () => {

    const mainTopic = topicNames.javascript.mainTopic;

    const subTopics = topicsJsonData.find(aTopic => aTopic.mainTopic === mainTopic).subTopics;

    const currentRoute = calculateCurrentRouteWithMainTopicOnly(mainTopic);

    const ShortDescriptionAboutJavascript = () =>
        <div className={'text-justify'}>
            <p>
                A programming language that is used in client-side and
                server-side programming. With the dawn of Chrome's V8 engine which gave birth to&nbsp;
                <a href={'https://nodejs.org/en/about/'}>Node JS</a>, Javascript is now fast and powerful for server
                side programming.
            </p>
            <p>
                <a href={'https://www.typescriptlang.org/'}><strong>Typescript</strong></a> adds static
                typing on top of Javascript language. Compiling Typescript converts to a Javascript, a process known as
                transpiling.
            </p>
        </div>
    ;

    const RenderMainTopic = () =>
        <MainTopic
            mainTopic={mainTopic}
            ShortDescriptionDiv={ShortDescriptionAboutJavascript}
            subTopics={subTopics}
        />
    ;

    const SwitchRouteForJavascriptTopic = () =>
        <>
            <BreadCrumbsNavigation/>
            <RenderTitle titleOfThePage={mainTopic}/>
            <RenderMainTopic/>
        </>
    ;

    return (
        <>
            <SwitchRouteForJavascriptTopic/>
        </>
    );
};

export default Javascript;
